

.modal-wrapper {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 500;}



.modal-content {
  background: white;

  width: 100%;
  max-width: 500px;
  height: 100%;
  max-height: 800px;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
  text-align: center;
}
.modal-content2 {
  //background: white;

  width: 100%;
 max-width: 1500px;
 min-width: 750px;
 min-height: 800px;
 // height: 100%;
 // max-height: 800px;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
  text-align: center;
}
.oval {
  min-width: 200px;
  min-height: 200px;
  position: absolute;
  left: 42vw;
  top: 25vh;
}

.modal-container {
  background-color: #c6c5c5;
 // border: 1px solid red;
}

.line-form-container {
  justify-content: center;
  height: 70vh;
  //border: 1px solid red;
}
.line-form-container2 {
  justify-content: space-around;
  min-height: 250px;
 // position: relative;
 // flex-wrap: wrap;
 border: 1px solid rgb(24, 26, 22);

 height: 40vh;
 // border: 1px solid red;
}

.line-form-container input {
  font-size: 16px;
}

.section-container {
  position: relative;
  top: 50px;

  height: 80%;
  width: rem(230px);
 // border: 1px solid greenyellow;
}
.section-container2 {
  position: relative;
 // top: 50px;

 //height: 70%;
  width: rem(230px);
 // border: 1px solid greenyellow;
}
.section-container3 {
  position: relative;
 // display: ruby;
 // top: 50px;
 //min-height: 200px;
 justify-content: center;
 align-items: stretch;
 // height: 200px;
 // width: 1100px;
  border: 1px solid rgb(20, 20, 20);
}
.choose-box {
  width: 150px;
  height: 40px;
  cursor: pointer;
  border-radius: 5%;
  background-color: rgba(0, 0, 0, 0.979);
  color: #fffffe;
  transition: 0.5s;
  margin: 0 auto;
}

.choose-box h4 {
  font-size: 18px;
  text-align: center;
  position: relative;
  bottom: 5px;
}

// .choose-box:hover {
//     background-color: rgb(255, 255, 255);
//     color: black;
// }

.inputs-card {
  position: fixed;

  height: 500px;
  top: 150px;
// border: 1px solid salmon;
  justify-content: space-around;
}
.inputs-card2 {
  position: relative;

  height: 500px;
  //top: 135px;
// border: 1px solid salmon;
  justify-content: space-around;
}
.inputs-card3 {
  position: relative;
display: flex;
  height: 130px;
 // top: 150px;
// border: 1px solid salmon;
  justify-content: space-around;
}
.titleEv {
  width: 230px;
}
.DetailEv {
  width: 430px;
}

.inputs-card input {
  height: 40px;
}

.clicked {
  background-color: rgb(255, 255, 255);
  color: black;
}

.box1 {
  position: relative;
  left: 0px;
}

.box4 {
  position: relative;
  right: 30px;
}

// .box4 {
//     position: relative;
//     right: 10px;
// }

.save-btn {
  width: 100%;
  max-width: 150px;
  height: 40px;
}

.close-modal-btn {
  border: 1px solid black;
  width: 150px;
  height: 40px;
  margin: 0 auto;
  position: relative;
 // right: 10px;
  text-align: center;
}

// .disabled-focus-line {
//     display: none;
// }

.focus-line {
  display: block;
  background-color: azure;
  width: 100px;
  margin: 0 auto;
  position: relative;
  top: 10px;
}

.inputs-card3 {
  padding-top: 5px;
}

.inputs-card4 {
  padding-top: 5px;
}

label {
  font-size: 18px !important;
  color: #6c757d ;
  text-align: left !important;
}

.MuiInputBase-input {
  color: azure ;
  font-size: 18px !important;
  font-weight: lighter !important;
}

.vertigo-report {
  position: relative;
  top: 25px;
}

.eco-btn h4 {
  position: relative;
  right: 20px;
}

.date-label {
  //border: 1px solid red;
  width: 220px;
  font-size: 14px !important;
  position: relative;
  top: 20px;
}
@media screen and (max-width: 1000px) {
  .modal-wrapper {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: left;
    align-items: left;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 500;
  }
  .modal-content2 {
  //  background: white;
 // transform: translate(0%, +30%);
  top: 0;
    width: 100%;
   max-width: 1500px;
   min-width: 750px;
   min-height: 800px;
   // height: 100%;
   // max-height: 800px;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
    text-align: left;
    //text-align: start;
  }}
  @media screen and (max-height: 500px) {
    .modal-wrapper {
      position: absolute;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: left;
      align-items: left;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 500;
    }
    .modal-content2 {
    //  background: white;
   transform: translate(0%, +30%);
    top: 0;
      width: 100%;
     max-width: 1500px;
     min-width: 750px;
     min-height: 800px;
     // height: 100%;
     // max-height: 800px;
      position: relative;
      border-radius: 10px;
      box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
      text-align: left;
      //text-align: start;
    }}