.date-serch-header {
    color: #212529;
    text-align: center;
    //width: 400px;
}

.list2 {
    width: 400px;
}

.date-range-container {
    position: relative;
    left: 150px;
}

.inputs-date-container {//the btn drawer
    //height: 85px;
}

.to-input-date { // the input TO
    position: relative;
    left: 10px;
}

.list-container {
    height: 100vh;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-evenly;
    //border: 1px solid red;
}