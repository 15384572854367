@font-face {
    font-family: rubik;
    src: url(../fonts/rubik/Rubik-Regular.ttf);
}


@font-face {
    font-family: rubik-light;
    src: url(../fonts/rubik/Rubik-Light.ttf);
}

@font-face {
    font-family: rubik-bold;
    src: url(../fonts/rubik/Rubik-Bold.ttf);
}

@font-face {
    font-family: rubik-semiBold;
    src: url(../fonts/rubik/Rubik-SemiBold.ttf);
}


