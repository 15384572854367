.signup-card-container {
  height: 600px;
  justify-content: left;
  margin: 0 auto;
  position: relative;
  top: 20px;
}
// .signup-inputs {
//     background-color: pink;
// }

.signup-inputs-container {
  //border: 5px solid red;
  align-content:flex-start;
  position: relative;
  top: -20px;
}

.sign-mail {
 // border: 5px solid red;
  position: relative;
  top: 0px;
  margin:  3px 0px;
}
.sign-firstName {
 // border: 5px solid red;
  position: relative;
  top:0px;
  margin:  3px 0px;
}
.sign-lastName {
 // border: 5px solid red;
  position: relative;
  top: 0px;
  margin:  3px 0px;
}

.sign-tel {
 // border: 5px solid red;
  position: relative;
  top: 0px;
  margin:  3px 0px;
}
.sign-pass {
 // border: 5px solid red;
  position: relative;
  top: 0px;
  margin:  3px 0px;
}

.signup-checbox-container {
  position: relative;
  top: rem(60px);
  justify-content: space-around;
  color: black !important;
}

.signupbtn {
  position: relative;
  top: 10px;
  text-align: center;
}

.signup-title {
  position: absolute;
  bottom: 490px !important;
}

.card label {
  color: black;
}

.loginInput {
  color: rgb(66, 66, 66) !important;
}
