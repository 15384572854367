.login-card-container {
  height: 600px;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  top: 20px;
 // border: 2px solid red;
}

#inscription {
  color: black;
}
.login-card {
  background-color: azure;
}

.card-body {
  position: relative;
  bottom: 80px;
}

.card-body h2 {
  position: absolute;
  bottom: 390px;
  left: 0px;
  font-size: 50px;
  font-weight: bolder;
  z-index: 10;
}

.loginInput {
  max-width: 400px;
  height: 50px;
}

.loginbtn {
  max-width: 400px;
  height: 50px;
  font-size: 20px;
  cursor: pointer;
  background-color: rgb(150, 156, 156);
}

.login-page-container {
  justify-content: space-between;
}

.inputs-container {
  align-self: center;
  width: 400px;
}

.log-inputs-container {
  position: relative;
  top: rem(70px);
  justify-content: space-around;
}

.login-btn-container {
  position: relative;
  top: 150px;
  text-align: center;
}

.log-inputs-container input {
  font-size: 15px;
}

.pass-login-input {
  position: relative;
  top: 20px;
}

.signup-link:hover {
  color: black;
  background-color: rgba(168, 85, 157, 0.637);
}
