.table-container {
  position: relative;
  top: 40px;
}

.crud-icons {
  cursor: pointer;
}
 
.filterBy-input {
  width: 300px;
  position: relative;
  top: rem(30px);
}

.search-container {
  justify-content: space-evenly;
}
.partnerBox {
  //border: 1px solid red;
  position: relative;
  right: 350px;
}

.partnerBox1 {
  position: relative !important;
  left: rem(250px) !important;
}


// .table-body {
//     position: relative;
//     //left: 10px;
// }

td {
  text-align: center !important;
 border: 1px solid;
}

.call-date-in {
  width: 100px;
}

.vertigo-call-date,
.vertigo-vertigoRecivedDate,
.eco-paymentDateFees {
  width: 100px;
}
