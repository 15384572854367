.dashboard-header-container {
  justify-content: space-between;
   display: flex;
  //height: 25vh;
  position: relative;
  width: 100%;
  background-color: #b2b2b9; 
  //border: 2px solid red;
}

.user-box {
 // border: 1px solid salmon;
  //position: absolute;
  // top: 200px;
  //width: 80vw;
  //display: flex;
  text-align: center;
  position: relative;
  // right: rem(50px);
  //top: rem(40px);
}

.dash-header-box1 {
 // border: 1px solid red;
  display: block;
  position: relative;
 // left: rem(00px);
  width: 100%;
  //top: rem(20px);
  //height: 150px;
  justify-content: center;
  //margin: 0 auto;
  text-align: center;
  //left: 8%;
}

.dash-header-box1 h1 {
 //border: 1px solid salmon;
  align-self: center;
 // left: 250px;
  position: relative;
  display: block;
}
.response{
  border: 1px solid gray;
  border-radius: 0.4285rem;
}
.question-container{
  border: 1px solid blue;
  border-radius: 0.4285rem;
  margin: 20px;
  padding: 10px;
  background-color:rgb(205, 205, 210)
}
.switchButtonActif{
  display: block;
 border: 1px solid blue;
 border-radius: 0.4285rem;
 margin: 4px 1px;
}
.dash-header-box {
  //  position: relative;
  display: flex;
flex-wrap: wrap;
  //left: rem(17px);
}

.use-box p {
  text-align: center;
  align-self: center;
  font-size: rem(20px);
}

.user-box p span {
  font-weight: bold;
}

.img-header-container {
  //border: 1px solid red;
  //width: 900px;
  justify-content: space-between;
  //display: block;
  position: relative;
}

.btn-export {
  background-color: rgba(255, 255, 255, 0);
  opacity: 0;
  //border: 1px solid red;
  position: relative;
  // bottom: 30px;
  min-height: 40px;
  // width: 185px;
  //  right: 40px;
}

.logout-dropdown {
  position: relative;
  top: 60px !important;
  left: -30px !important;
}

.btn-logout {
  // width: 100px;
  // height: 30px;
  cursor: pointer;
  position: relative;
  top: rem(40px);
  //left: 50px;
}

.btn-export-container {
  width: 185px;
  min-height: 40px;
  border-width: 2px;
  border: none;
  position: relative;
  overflow: hidden;
  margin: 4px 1px;
  border-radius: 0.4285rem;
  background: aquamarine;
  position: relative;
  left: 20px;
}

.logo-container {
  justify-content: center;
  width: 100%;
  // position: relative;
  // right: 20px;
}

.avatar {
  cursor: pointer;
  position: relative;
  right: 50px;
}

.create-btn {
  position: relative;
  left: 45px;
}

.global-search-input {
  position: relative !important;
  top: 5px !important;
  left: 50px;
  //border: 1px solid red;
  //background-color: rgba(255, 255, 255, 0.623);
 // outline: 1px solid rgba(40, 57, 150, 0.726);
}

.global-search-inputText {
  font-size: 20px;
}
