.img-header-container {
  justify-content: center;
  display: flex;
 //border: 1px solid red;
 min-width: 150px ;
 //width: rem(900px);
  position: relative;
 // right: 20px;
}
.img-header-container2 {
  justify-content: center;
 display: flex;
 flex-wrap: wrap;
 // height: 400px;
  //flex-direction: row;
 // overflow:  visible;
// border: 1px solid red;
// min-width: 150px ;
 //width: rem(900px);
  position: relative;
 // right: 20px;
}
.cardTxt {
  position: relative;
  top: rem(80px);
  text-align: center;
}

.card1,
.card2,
.card3 {
  display: flex !important;
  flex-direction: row;
  height: rem(120px);
  width: 150px ;
}
.text1 p {
  color: #6c757d;
  position: relative;
  text-align: end;
}

.text1 span {
  position: absolute;
  bottom: -30px;
  right: 20px;
  font-size: rem(20px);
  color: #ffffff;
}

.kl {
  position: relative;
  top: rem(13px);
  left: rem(10px);
}

.testCard {
  position: relative;
  top: 0px;
  min-width: 150px ;
}
