body {
  margin: 0;
  padding: 0;
}

.main {
  flex-direction: column;
  display: flex;
  height: 90vh;
}
.main .top {
  display: flex;
  flex: 0 0 40%;
  flex-direction: row;
  overflow: hidden;
}
.main .top .box-1 {
  background: #e0e0e3;
  flex: 0 0 80%;
  position: relative;
}
.main .top .box-1 .box-1-container {
  max-height: 100%;
  overflow: auto;
  padding: 0.5rem 2rem;
}
.main .top .drag-x {
  background: linear-gradient(360deg, gray 50%, white 100%);
  cursor: col-resize;
  flex: 0 0 8px;
}
.main .top .box-2 {
  background: url("../../assets/img/affiche.png") center/cover;
  flex: 1;
  animation: animback 12s infinite;
}
@keyframes animback {
  33% {
    background: url("../../assets/img/affiche2.png") top left / 100% 100%;
  }
  66% {
    background: url("../../assets/img/interieur.png") top left / 100% 100%;
  }
}
.main .drag-y {
  background: linear-gradient(90deg, gray 55%, white 100%);
  cursor: row-resize;
  caret-color: auto;
  flex: 0 0 8px;
  z-index: 10;
}
.main .bottom {
  background: #e0e0e3;
  flex: 1;
  overflow: hidden;
}
.main .bottom .bottom-container {
  max-height: 90%;
  overflow: auto;
  display: flex;
}
.main .bottom .bottom-container h3 {
  padding: 1rem;
  margin: 0;
}
.main .bottom .bottom-container p {
  padding: 2rem;
}

@media screen and (max-width: 1000px) {
  .main .top {
    flex: 0 0 40%;
    width: 100%;
    flex-direction: column;
    overflow: scroll;
  }
  .main .top .box-2 {
    display: none;
  }
  .main .bottom {
    overflow: scroll;
  }
  .main .bottom .bottom-container {
    max-height: 90%;
    overflow: auto;
    display: block;
  }
}
