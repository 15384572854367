.nav-container {
  display: flex;
  //position: relative;
  border-bottom: solid 0.3em;
  border-bottom-color: rgb(90, 92, 92);
  text-align: center;
  justify-content: space-between;
  align-items: baseline;
}
.navlogo-container {
  display: flex;
  position: relative;

  text-align: center;
  align-items: center;
}
.nav-user {
  display: flex;
  position: relative;
  align-items: center;
  text-align: center;

  // left: 900px;
  //margin-top: 10px;
  //padding: 10px;
  //align-items: baseline;
}
#logobetyossef {
  width: 40px;
  height: 40px;
  align-items: center;
  margin: 10px;
}
.nav-container2 {
  display: flex;
  border-bottom: solid 0.3em;
  border-bottom-color:  rgb(90, 92, 92);
  text-align: center;
  align-items: baseline;
}
h1 {
  display: flex;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
h3 {
  margin: auto;
}

.nav-user1 {
  //margin-right: 10px;
  padding-right: 10px;
}
.nav-histodons2 {
  background-color: #3c4b3c; /* Green */
  border: solid 0.2em;
  color: whitesmoke;
  padding: 15px 32px;
  margin: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  font-size: 16px;
}
@media screen and (max-width: 1000px) {
  .nav-container {
    display: block;
  }
  .nav-user {
    display: block;
  }
}
